.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.error-wrapper {
  min-width: 55%;
  max-width: 80%;
  background: #ffffff;
}

.error-header-wrapper {
  background: #7b7b7b;
  border-bottom: 70px solid #b4b3b1;
  height: 240px;
  color: #333333;
}

.error-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f1ee;
  border-style: solid;
  border-width: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-color: #ffffff;
  margin: -190px 1.5rem 1.5rem 1.5rem;
  padding: 1rem;
}

.error-content-wrapper > h1 {
  color: #333333;
}

.error-image-wrapper {
  margin-bottom: 1rem;
  margin-top: -5rem;
}
